<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill" *ngIf="localRole == 'SuperAdmin' || localRole == 'Admin' || localRole ==
  'Executive'">
  <div class="row" style="justify-content: space-between;margin: unset;">
    <div class="col-sm-7" style="align-content: center;">
      <h6 style="margin-bottom: unset;">
        {{'EXECUTIVE.SEARCH-BY-NAME-LIST' | translate}}
      </h6>
    </div>
    <div class="d-flex">
      <div style="width: 180px;">
        <ng-select [ngClass]="'ng-select'" [options]="dataTeacherAll"
          *ngIf="selectedTeacher != null && selectedTeacher != '' && selectedTeacher != undefined"
          [(ngModel)]="selectedTeacher" (ngModelChange)="getDataSubjectTeacher($event)">
          <ng-template let-option="dataStudentAll">
            <div class="famfamfam-flags {{dataTeacherAll?.value.toLowerCase()}}"></div>
            {{dataTeacherAll.label}}
          </ng-template>
        </ng-select>
        <i class="feather icon-user" style="position: absolute;right: 364px;top: 27px;"></i>
      </div>

      <p *ngIf="selectedTeacher == null || selectedTeacher == '' || selectedTeacher == undefined"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>

      <button type="button" class="btn download-excel" data-toggle="tooltip" data-placement="bottom"
        title="{{'EXECUTIVE.DOWNLOAD-THE-HIGHTEST-REVENUE' | translate}}" (click)="exportexcel('Report-income-teacher')"
        style="width: 180px;
        border: 1px solid #ddd;
        margin-left: 11px;
        border-radius: 8px;">
        <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
      </button>
    </div>
  </div>
</app-card>

<div class="row">
  <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
      title="{{'EXECUTIVE.DOWNLOAD-THE-HIGHTEST-REVENUE' | translate}}"
      style="margin-bottom: 10px;margin-right: 5px;float: right;" (click)="exportexcel('Report-income-teacher')">
      <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
    </button>
  </div> -->
  <!-- Income All Start-->
  <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
    <!-- <app-card [hidHeader]="true" cardClass=" order-card" class="bg-custom1"> -->
    <div class="card bg-custom1">
      <div class="row" style="justify-content: space-between; margin: unset;">
        <h6 class="">{{'EXECUTIVE.REVENUE-REPORT-OF-ALL-COURSES' | translate}}</h6>
        <h6 class="text-right" *ngIf="totalSubject != null">
          <!-- <i class="feather icon-activity float-left icon-1"></i> -->
          <span>{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}}</span>
        </h6>
      </div>
      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.all.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
    </div>

    <!-- </app-card> -->
    <!-- <app-card [hidHeader]="true" cardClass="bg-c-green order-card"> -->
    <div class="card bg-custom2">
      <div class="row" style="justify-content: space-between; margin: unset;">
        <h6 class="">{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth2}}</h6>
        <h6 class="text-right" *ngIf="totalSubject != null">
          <!-- <i class="feather icon-activity float-left icon-2"></i> -->
          <span>{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}}</span>
        </h6>
      </div>

      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.lastMonth.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
    </div>

    <!-- </app-card>
    <app-card [hidHeader]="true" cardClass="bg-c-red order-card"> -->
    <div class="card bg-custom3">
      <div class="row" style="justify-content: space-between; margin: unset;">
        <h6 class="">{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth1}}</h6>
        <h6 class="text-right" *ngIf="totalSubject != null">
          <!-- <i class="feather icon-activity float-left icon-3"></i> -->
          <span>{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}}</span>
        </h6>
      </div>

      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.thisMonth.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
      <!-- </app-card> -->
    </div>
    <!-- Income All End-->
  </div>
  <!-- Top Course Start-->
  <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" style="padding-left: unset;">
        <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
          <div class="card-income">
            <strong>{{'EXECUTIVE.TOP-10-OF-THE-HIGHTEST-REVENUE' | translate}}<i
                class="fas fa-level-up-alt text-c-green" style="margin-left: 8px;"></i></strong>
          </div>
          <perfect-scrollbar style="background-color: white; border-radius: 5px;
            height: 188px; top: 0px;" *ngIf="topBest != null && topBest != undefined">
            <div class="" *ngIf="!dataLoading2">
              <!-- <span style="    display: flex;
              justify-content: end;
              margin: 0 15px;"><b style="color: green;">฿</b> {{'EXECUTIVE.REVENUE' | translate}}</span> -->
              <div class="row" style="margin: unset;padding: 10px 0;border-bottom: 1px dashed #c9c9c9;" *ngFor="let TenBest of topBest.topBest;index as
                i">
                <div class="col-sm-auto p-r-0">
                  <p style="margin-bottom: unset;"><b>Top {{i+1}}</b></p>
                </div>
                <div class="col">
                  <div style="display:flex;justify-content: space-between;">
                    <p class="m-b-0" style="text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;width: 200px;" ngbTooltip="{{dataLanguage=='th'? TenBest.nameTh:TenBest.nameEn}}">
                      {{dataLanguage=='th'? TenBest.nameTh:TenBest.nameEn}}</p>
                    <span><b style="color: green;">฿</b> {{'EXECUTIVE.REVENUE' | translate}}</span>
                  </div>
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- <td> -->
                      <!-- </td> -->
                    </div>
                    <div class="col">
                      <h6 class="m-b-0 float-right">{{TenBest.price | number}} {{'EXECUTIVE.BAHT' | translate}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div style="text-align: center;margin: 20px;" *ngIf="dataLoading2">
            <div class="spinner-border text-info" role="status">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
          <p *ngIf="!dataLoading2 && topBest == null" style="text-align: center;padding-top: 10px;">
            <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
          </p>
        </app-card>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" style="padding-left: unset;">
        <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
          <div class="card-income">
            <strong>{{'EXECUTIVE.TOP-10-OF-THE-LOWEST-REVENUE' | translate}}<i class="fas fa-level-down-alt text-c-red"
                style="margin-left: 8px;"></i>
            </strong>
          </div>
          <perfect-scrollbar style="background-color: white; border-radius: 5px;
            height: 188px; top: 0px;" *ngIf="topWorst != null && topWorst !=
            undefined">
            <div class="" *ngIf="!dataLoading2">
              <div class="row" style="margin: unset;padding: 10px 0;border-bottom: 1px dashed #c9c9c9;" *ngFor="let TenWorst of topWorst.topWorst;index
                as i">
                <div class="col-sm-auto p-r-0">
                  <p style="margin-bottom: unset;"><b>Top {{i+1}}</b></p>
                </div>
                <div class="col">
                  <div style="display:flex;justify-content: space-between;">
                    <p class="m-b-0" style="text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;width: 200px;"
                      ngbTooltip="{{dataLanguage=='th'? TenWorst.nameTh:TenWorst.nameEn}}">
                      {{dataLanguage=='th'? TenWorst.nameTh:TenWorst.nameEn}}</p>
                    <span><b style="color: #c20c00;">฿</b> {{'EXECUTIVE.REVENUE' | translate}}</span>
                  </div>
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- <td>
                      </td> -->
                    </div>
                    <div class="col">
                      <h6 class="m-b-0 float-right">{{TenWorst.price | number}} {{'EXECUTIVE.BAHT' | translate}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div style="text-align: center;margin: 20px;" *ngIf="dataLoading2">
            <div class="spinner-border text-info" role="status">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
          <p *ngIf="!dataLoading2 && topWorst == null" style="text-align: center;padding-top: 10px;">
            <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
          </p>
        </app-card>
      </div>
    </div>
  </div>
  <!-- Top Course End-->

  <!--Student List Start-->
  <div class="col-12">
    <app-card cardClass="card-datatable" [options]="false" [hidHeader]="true">
      <div class="search-barrr" style="padding-bottom: 15px;">
        <input type="text" [(ngModel)]="searchModel" (ngModelChange)="searchChange($event)"
          placeholder="{{'TEACHER.Q-AND-A.SEARCH' | translate}}...">
        <i class="feather icon-search"></i>
      </div>
      <div class="table-scroll-x" *ngIf="dataStudent != null && dataStudent != '' && dataStudent != undefined">
        <table class="table-version-z" *ngIf="!dataLoading1">
          <thead>
            <tr>
              <th class="first">{{'ADMIN.COURSE.NO' | translate}}</th>
              <th style="text-align: center;">{{'EXECUTIVE.NAME' | translate}}</th>
              <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
              <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
              <th style="text-align: center;">{{'EXECUTIVE.ATTENDANCE-STATUS' | translate}}</th>
              <th class="last">{{'EXECUTIVE.COURSE-PRICE' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let std of dataStudent;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
              <td style="text-align: center;">{{i+1}}</td>
              <td>{{std.dataStudent.fullNameTh}}</td>
              <td>{{std.dataStudent.email}}</td>
              <td>{{std.dataSubject.nameTh}}</td>
              <td style="text-align: center;">
                <a *ngIf="std.typePayment == 'bank-transfer'" class="badge
                badge-light-danger-c badge-pill mr-2">{{'EXECUTIVE.LOST-MONEY' | translate}}</a>
                <a *ngIf="std.typePayment == 'free'" class="badge
                  badge-light-success-c badge-pill mr-2">{{'EXECUTIVE.FREE' | translate}}</a>
                <a *ngIf="std.typePayment == 'ticket'" class="badge
                  badge-light-primary-c badge-pill mr-2">{{'EXECUTIVE.USE-TICKET' | translate}}</a>
                <a *ngIf="std.statusPay == 'reject_amount'" class="badge
                  badge-light-danger-c badge-pill mr-2">{{'EXECUTIVE.CANCELED' | translate}}</a>
                <a *ngIf="std.statusPay == 'pending'" class="badge
                  badge-light-warning-c badge-pill mr-2">{{'EXECUTIVE.WAITING-FOR-VERIFY' | translate}}</a>
              </td>
              <td style="text-align: center;">{{std.dataSubject.price | number}}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="pageCount" class="page-control">
          <p style="margin-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} <span class="ml-1 mr-1">{{page}}</span>
            {{'ADMIN.Q-AND-A.OF' |
            translate}} <span class="ml-1">{{pageCount}}</span></p>
          <div>
            <button (click)="pageChange('prev')" class="prev">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
            <button (click)="pageChange('next')" class="next">{{'ADMIN.Q-AND-A.NEXT' | translate}}</button>
          </div>
        </div>
        <div style="text-align: center;margin: 20px;" *ngIf="dataLoading1">
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div>
      </div>
      <p *ngIf="dataStudent == null || dataStudent == '' || dataStudent == undefined"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
    </app-card>
  </div>
  <!--Student List End-->
</div>
<table witdh="100%" border="1" class="table" #table11 id="excel-table-Report-income-teacher" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REVENUE-REPORT-OF-TEACHER-COURSES' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngFor="let item of dataTeacherAll">
    <tr *ngIf="item.value == selectedTeacher">
      <td>{{'EXECUTIVE.TEACHER-NAME' | translate}} {{item.label}} ({{'EXECUTIVE.TEACHER-ID' |
        translate}}{{selectedTeacher}})</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REVENUE-REPORT-OF-ALL-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth2}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth1}}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td *ngIf="totalSubject != null">{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.lastMonth.length}} {{'TEACHER.COURSES' |
        translate}})</td>
      <td *ngIf="totalSubject == null">{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject != null">{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.lastMonth.length}} {{'TEACHER.COURSES' |
        translate}})</td>
      <td *ngIf="totalSubject == null">{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject != null">{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.thisMonth.length}} {{'TEACHER.COURSES' |
        translate}})</td>
      <td *ngIf="totalSubject == null">{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}}
        ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-HIGHTEST-REVENUE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'ADMIN.COURSE.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'ADMIN.COURSE.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-INCOME' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topBest != null">
    <tr *ngFor="let TenBest of topBest.topBest;index as i">
      <td>Top {{i+1}}</td>
      <td>{{TenBest.nameTh}}</td>
      <td>{{TenBest.nameEn}}</td>
      <td>฿ {{TenBest.price | number}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-LOWEST-REVENUE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'ADMIN.COURSE.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'ADMIN.COURSE.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-INCOME' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topBest != null">
    <tr *ngFor="let TenBest of topBest.topWorst;index as i">
      <td>Top {{i+1}}</td>
      <td>{{TenBest.nameTh}}</td>
      <td>{{TenBest.nameEn}}</td>
      <td>฿ {{TenBest.price | number}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.LEARNER-NAME-LIST' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.NAME' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
      <th>{{'EXECUTIVE.ATTENDANCE-STATUS' | translate}}</th>
      <th>{{'EXECUTIVE.COURSE-PRICE' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="dataStudent != null">
    <tr *ngFor="let std of dataStudent">
      <td>{{std.dataStudent.fullNameTh}}</td>
      <td>{{std.dataStudent.email}}</td>
      <td>{{std.dataSubject.nameTh}}</td>
      <td>
        <a *ngIf="std.typePayment == 'bank-transfer'" class="badge
          badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.LOST-MONEY' | translate}}</a>
        <a *ngIf="std.typePayment == 'free'" class="badge
          badge-light-success badge-pill f-12 mr-2">{{'EXECUTIVE.FREE' | translate}}</a>
        <a *ngIf="std.typePayment == 'ticket'" class="badge
          badge-light-primary badge-pill f-12 mr-2">{{'EXECUTIVE.USE-TICKET' | translate}}</a>
        <a *ngIf="std.statusPay == 'reject_amount'" class="badge
          badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.CANCELED' | translate}}</a>
        <a *ngIf="std.statusPay == 'pending'" class="badge
          badge-light-warning badge-pill f-12 mr-2">{{'EXECUTIVE.WAITING-FOR-VERIFY' | translate}}</a>
      </td>
      <td>{{std.dataSubject.price | number}}</td>
    </tr>
  </tbody>
</table>