<div class="container"
  *ngIf="this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><svg
        xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
      </svg></a>



    <ul [ngClass]="{ 'collapsed': menuCollapsed }" class="pcoded-navbar pcoded-inner-navbar">
      <!-- Navigation items here -->
    </ul>


    <h6 style="margin: 0;font-size: 18px;color: var(--mj-color-1);">ม. มหาจุฬาลงกรณราชวิทยาลัย วิทยาเขตนครสวรรค์
    </h6>
    <!-- <h6 style="margin: 0;font-size: 18px;color: var(--mj-color-1);">มหาวิทยาลัยมหาจุฬาลงกรณราชวิทยาลัย วิทยาเขตนครสวรรค์
    </h6> -->
    <!-- <div style="margin: 0;font-size: 18px;">
      <img src="../../../../../assets/images/logo_monk/logo-monk-mcu-full-name.png" alt="" style="width: 85%;">
    </div> -->

    <!-- <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
      <p style="margin-top: 54px;margin-left: -64px;font-size: 11px;color: white;">Version 3.1 </p>
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb"> -->
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
        class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
        <path
          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
      </svg>
    </a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <div class="text-left" style="min-width: 150px;" *ngIf="dataLocal != null && dataLocal.role == 'SuperAdmin'">
      <div class="btn-cust" *ngIf="dataPortalAll && dataPortalAll.length > 0">
        <ng-select [ngClass]="'ng-select'" [options]="dataPortalAll" [(ngModel)]="selectedPortal"
          style="background-color: #f4f9f2; border-radius: 4px;" *ngIf="dataPortalAll != ''"
          (ngModelChange)="selectProtal($event)">
          <ng-template let-option="dataPortalAll">
            <div class="famfamfam-flags {{dataPortalAll?.value.toLowerCase()}}"></div>
            {{dataPortalAll.label}}
          </ng-template>
        </ng-select>
        <p *ngIf="!dataPortalAll" style="text-align: center;"><b>*
            {{'ADMIN.NO-DATA-SHOWING' | translate}}</b></p>
      </div>
    </div>
    <app-nav-left class="mr-auto"></app-nav-left>
    <div *ngIf="dataLocal != null && dataLocal.role == 'SuperAdmin'">
      <h3 style="color: var(--mj-color-1);">
        Portal-{{showPortalSelect}}</h3>
    </div>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>