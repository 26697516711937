import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/core/constants/constant';
import { Role } from 'src/app/core/models/role';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';
import { ReportAllService } from 'src/app/core/services/report-all/report-all.service';
import * as htmlToImage from 'html-to-image';
import { formatNumber } from '@angular/common';
import * as moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';

@Component({
  selector: 'app-report-courses',
  templateUrl: './report-courses.component.html',
  styleUrls: ['./report-courses.component.scss'],
})
export class ReportCoursesComponent implements OnInit {
  urlImage = Constants.REST_API_MAIN;
  baseURL = Constants.REST_API_MAIN;
  dataLanguage: any;
  id: any;
  reportSubjectAll: any;
  topRating: any;
  dataLoading1: boolean = true;
  dataLoading2: boolean = true;
  dataLoadingCer: boolean = true;
  dataTeacherAll: Array<any> = [];
  dataSubjectAll: Array<any> = [];
  selectedTeacher: any = '';
  selectedSubject: any = '';
  localRole: any;
  detailTeachers: any;
  address: any;
  ListStudent: any;
  Liststd: any;
  dataExamSubject: any;
  studentGraduate: any;
  studentAndCertificate: any;
  dataGraduateTheCourse: any;
  dataGraduateTheCourseById: any;
  dataStudentAndCertificate: any;
  dataPreTest: any;
  dataStudent: any;
  dataItemByTd: any;

  userCertificateData: any;
  showCertificate: boolean = false;
  btnDownload: boolean = false;
  certificateId = '';
  langCourse: any;
  dateCreate: any;
  dataCertificate: any;
  imgLogo: string = '';
  imgsignature: string = '';
  dataPostTestt: any;
  public ind;
  exam: any;
  dataLearnStudent: any;
  progress: string;
  studyingCount: any;
  studyingList: any;
  dataStudyingById: any;
  startDate: any;
  endDate: any;
  pretestList: any;
  posttestList: any;
  dataLocal: any;
  dataLoadingClickCer: boolean = false;
  formattedPhoneNumber: string;
  citizen: any;
  currentTabReport: string = 'top';
  dataTopBaseTab: any;
  dataTopWorstTab: any;
  chartReportStudying: any;
  chartgraduation: any;
  chartCertificate: any;
  currentTabstudy: string = 'studying';

  searchTermPretest: string = '';
  filteredPretest: any[] = [];
  pageLengthPretest: number = 10;
  currentPagePretest: number = 1;

  searchTermPosttest: string = '';
  filteredPosttest: any[] = [];
  pageLengthPosttest: number = 10;
  currentPagePosttest: number = 1;
  @ViewChild('myLargeModalLabel') myLargeModalLabel;
  @ViewChild('myFindReportExamSubject') myFindReportExamSubject;
  @ViewChild('myStudentGraduateTheCourse') myStudentGraduateTheCourse;
  @ViewChild('myStudentStudyingList') myStudentStudyingList;
  @ViewChild('myCountStudentAndCertificate') myCountStudentAndCertificate;
  @ViewChild('printCertificate') printCertificate;
  @ViewChild('showGraduateTheCourse') showGraduateTheCourse;
  @ViewChild('myCountStudentStudying') myCountStudentStudying;
  constructor(
    private adminSharedDataService: AdminSharedDataService,
    private adminService: AdminService,
    private reportAllService: ReportAllService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit() {
    var local = localStorage.getItem('currentUserLogin');
    if (local != null) {
      this.dataLocal = JSON.parse(local);
      this.localRole = this.dataLocal.role;
      if (this.dataLocal.role == Role.SuperAdmin || this.dataLocal.role == Role.Admin) {
        this.getTeacher();
      } else if (this.dataLocal.role == Role.Teacher) {
        this.id = this.dataLocal.id;
        this.reportSubjectAllTeacher(this.id);
        this.topRatingSubjectList(this.id);
      } else if (this.dataLocal.role == Role.Executive) {
        this.getTeacher();
      } else {
      }
    }
    this.onChangSelectPortal();
    this.getLanguage();
  }
  getLanguage() {
    this.adminSharedDataService.getDataLanguage.subscribe(async (message) => {
      if (message == '') {
        this.dataLanguage = localStorage.getItem('lang');
      } else {
        this.dataLanguage = message;
      }
    })

  }
  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        if (this.dataLocal.role == Role.SuperAdmin || this.dataLocal.role == Role.Admin) {
          this.getTeacher();
        } else if (this.dataLocal.role == Role.Teacher) {
          this.id = this.dataLocal.id;
          this.reportSubjectAllTeacher(this.id);
          this.topRatingSubjectList(this.id);
        } else if (this.dataLocal.role == Role.Executive) {
          this.getTeacher();
        } else {
        }
      }
    });
  }

  async OpenList(data: any) {
    var dataOpenList: any = [];
    var dateSuccess: any = null;
    var dateSuccessPreTest: any = null;
    var Start: number = 0;
    var End: number = 0;
    if (data.countStudentLearn != 0) {
      this.myLargeModalLabel.show();
      for (let i = 0; i < data.dataStudents.length; i++) {
        var idSdt = data.dataStudents[i].dataStudent;
        await this.adminService.FindPreTestAndPostTestByidStudent(data.subjectId, idSdt.stdntId).then((response) => {
          if (response.status == true) {
            // setTimeout(() => {
            var dataresponse = response.data.result;
            if (dataresponse.queryPreTestResults != null) {
              const datePreTest = new Date(dataresponse.queryPreTestResults.date);
              const resultPreTest = datePreTest.toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              });
              dateSuccessPreTest = resultPreTest;

              if (dataresponse.queryPostTestResults != null) {
                const date = new Date(dataresponse.queryPostTestResults.date);
                const result = date.toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                });
                dateSuccess = result;
              } else {
                dateSuccess = null;
              }
            } else {
              dateSuccessPreTest = null;
              dateSuccess = null;
            }
            var SumHouse;
            if (dataresponse.queryPreTestResults != null) {
              var startDate = moment(new Date(dataresponse.queryPreTestResults.date), "DD.MM.YYYY HH:MM");
              var endDate;
              if (dataresponse.queryPostTestResults != null) {
                endDate = moment(new Date(dataresponse.queryPostTestResults.date), "DD.MM.YYYY HH:MM");
              } else {
                endDate = moment(new Date(), "DD.MM.YYYY HH:MM");
              }
              SumHouse = endDate.diff(startDate, 'hours');

            } else {
              SumHouse = 0;
            }

            dataOpenList.push({
              dataStudents: data.dataStudents[i],
              datePreTest: dateSuccessPreTest,
              datePostTest: dateSuccess,
              house: SumHouse,
              id: data.subjectId
            });
            // }, 1000);
          }
        });
      }
      this.Liststd = dataOpenList;
    }
  }

  getTeacher() {
    var getSuccess: any;
    var data: any = [];
    this.adminService.fetchDataAllMemmerUser().then((response) => {
      getSuccess = response;

      for (let i = 0; i < getSuccess.dataTeachers.length; i++) {
        data.push({
          value: getSuccess.dataTeachers[i].por.tchId,
          label: getSuccess.dataTeachers[i].por.fullNameTh,
        });
      }
      this.dataTeacherAll = data;

      this.selectedTeacher = this.dataTeacherAll.length > 0 ? this.dataTeacherAll[0].value : "";


      this.getDataSubjectTeacher(this.selectedTeacher);
    });
  }

  getDataSubjectTeacher(idTeacher: any) {
    this.dataLoading1 = true;
    this.dataLoading2 = true;
    this.reportSubjectAllTeacher(idTeacher);
    this.topRatingSubjectList(idTeacher);
    this.getDetailTeacher(idTeacher);
  }

  getDetailTeacher(Idteacher: any) {
    this.adminService.fetchDataAllMemmerUser().then((res) => {
      // console.log(res);

      this.detailTeachers = res.dataTeachers.find((s) => s.por.tchId == Idteacher);

      if (this.detailTeachers != undefined) {
        this.citizen = this.detailTeachers.por.citizenId.slice(0, -4).replace(/\d/g, '*') + this.detailTeachers.por.citizenId.slice(-4);
        if (this.detailTeachers.por.phoneNumber != '' && this.detailTeachers.por.phoneNumber != null && this.detailTeachers.por.phoneNumber != undefined && this.detailTeachers.por.phoneNumber.length === 10) {
          this.formattedPhoneNumber = this.detailTeachers.por.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '******$3');
        }
        this.address = this.detailTeachers.por.address.split(',');
      } else {
        this.address = [];
      }
    });

  }
  onClickTopRatingBase() {
    this.currentTabReport = 'top'
    this.dataTopBaseTab = this.topRating.topBest

  }
  onClickTopWorst() {
    this.currentTabReport = 'worst'
    this.dataTopWorstTab = this.topRating.topWorst

  }
  onclickChartStudying() {
    this.currentTabstudy = 'studying'
  }
  onclickChartGraduation() {
    this.currentTabstudy = 'graduation'
  }
  onclickChartCertificate() {
    this.currentTabstudy = 'certificate'
  }
  reportSubjectAllTeacher(id: any) {
    this.dataSubjectAll = [];
    this.dataExamSubject = '';
    this.studentGraduate = '';
    this.studentAndCertificate = '';
    this.adminService.ReportSubjectAllTeacher(id).then((res) => {
      var success = res;
      // console.log(res);
      if (success.status == true && success.data.length > 0) {
        if (
          success.data != null &&
          success.data != undefined &&
          success.data != '' &&
          success.data.length != 0
        ) {
          var data: any = [];
          this.reportSubjectAll = success.data;
          for (let i = 0; i < this.reportSubjectAll.length; i++) {
            data.push({
              value: this.reportSubjectAll[i].subjectId,
              label: `${this.reportSubjectAll[i].nameTh}(${this.reportSubjectAll[i].nameEn})`,
            });
          }
          this.dataSubjectAll = data;

          this.selectedSubject = this.reportSubjectAll[0].subjectId;
          this.getFindReportExamSubject(this.selectedSubject);
          this.getFindReportStudentGraduateTheCourse(this.selectedSubject);
          this.getFindReportCountStudentAndCertificate(this.selectedSubject);
          this.dataLoading1 = false;
        } else {
          this.reportSubjectAll = null;
          this.dataLoading1 = false;
        }
      } else {
        this.dataSubjectAll = null;
        this.reportSubjectAll = null;
        this.dataLoading1 = false;
      }
      // console.log("--------->",this.dataSubjectAll);
    });
  }

  getFindReportExamSubject(subjectId: string) {
    this.dataLoading1 = true;
    this.reportAllService.GetFindReportExamSubject_(subjectId).then((res) => {
      if (res.status) {
        this.dataExamSubject = res.data;
        this.dataLoading1 = false;
        this.pretestList = res.data;
        this.posttestList = res.data;

        this.filteredPretest = this.pretestList.pretest;
        this.filteredPosttest = this.posttestList.posttest;
        this.getFindReportStudentGraduateTheCourse(subjectId);
        this.getFindReportCountStudentAndCertificate(subjectId);
        this.FindReportStudentInformationStudying(subjectId);
      }
      // console.log("GetFindReportExamSubject_",this.dataExamSubject);
    });

  }

  async getFindReportStudentGraduateTheCourse(subjectId: string) {
    this.dataLoading1 = true;
    await this.reportAllService
      .GetFindReportStudentGraduateTheCourse_(subjectId)
      .then((res) => {
        if (res.status) {
          this.studentGraduate = res.data;

          this.dataLoading1 = false;
          // this.chartgraduation = {
          //   series: [
          //     {
          //       name: "Studying",
          //       data: [this.studentGraduate.countListSudentGraduate, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          //       color: "#4ECDC4",
          //     }
          //   ],
          //   chart: {
          //     height: 350,
          //     type: "line",
          //     zoom: {
          //       enabled: false
          //     }
          //   },
          //   dataLabels: {
          //     enabled: false
          //   },
          //   stroke: {
          //     curve: "straight"
          //   },
          //   // title: {
          //   //   text: "Product Trends by Month",
          //   //   align: "left"
          //   // },
          //   grid: {
          //     row: {
          //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          //       opacity: 0.5
          //     }
          //   },
          //   xaxis: {
          //     categories: [
          //       "Jan",
          //       "Feb",
          //       "Mar",
          //       "Apr",
          //       "May",
          //       "Jun",
          //       "Jul",
          //       "Aug",
          //       "Sep",
          //       "Oct",
          //       "Nov",
          //       "Dec"

          //     ]
          //   }
          // };
        }
        this.onclickChartGraduation();
      });
  }

  getFindReportCountStudentAndCertificate(subjectId: string) {
    this.dataLoading1 = true;
    this.reportAllService
      .GetFindReportCountStudentAndCertificate_(subjectId)
      .then((res) => {
        if (res.status) {
          this.studentAndCertificate = res.data;
          this.dataLoading1 = false;

        }
        // this.chartCertificate = {
        //   series: [
        //     {
        //       name: "Certificate",
        //       data: [this.studentAndCertificate.countListCertificate, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //       color: "#4CAF50"
        //     }
        //   ],
        //   chart: {
        //     height: 350,
        //     type: "line",
        //     zoom: {
        //       enabled: false
        //     }
        //   },
        //   dataLabels: {
        //     enabled: false
        //   },
        //   stroke: {
        //     curve: "straight"
        //   },
        //   // title: {
        //   //   text: "Product Trends by Month",
        //   //   align: "left"
        //   // },
        //   grid: {
        //     row: {
        //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //       opacity: 0.5
        //     }
        //   },
        //   xaxis: {
        //     categories: [
        //       "Jan",
        //       "Feb",
        //       "Mar",
        //       "Apr",
        //       "May",
        //       "Jun",
        //       "Jul",
        //       "Aug",
        //       "Sep",
        //       "Oct",
        //       "Nov",
        //       "Dec"
        //     ]
        //   }
        // };
        this.onclickChartCertificate();
      });
  }

  topRatingSubjectList(id: any) {
    this.adminService.TopRatingSubjectList(id).then((res) => {

      var success = res;

      if (success.status == true) {
        if (success.topBest.length > 0 || success.topWorst.length > 0) {
          this.topRating = success;
        } else {
          this.topRating = null;
        }
        this.dataLoading2 = false;
      } else {
        this.topRating = null;
        this.dataLoading2 = false;
      }
      this.onClickTopRatingBase();
    });
  }
  loading = true;
  openFindReportExamSubject(data: any) {
    this.loading = true;
    if (data != '') {
      this.myFindReportExamSubject.show();
      // this.myFindReportExamSubject.show();
      this.dataStudent = data.dataStudent;
      this.dataPreTest = data.dataPreTest;

      this.dataPostTestt = data.dataPostTest;
      if (this.dataPostTestt != '') {
        setTimeout(() => {
          this.loading = false;
        });
      }
    }
  }
  openFindReportStudentGraduateTheCourse(data: any) {
    if (data != '') {
      this.myStudentGraduateTheCourse.show();
      this.dataGraduateTheCourse = data;
    }
  }
  openViewStudyingList(dataItem: any) {
    this.myCountStudentStudying.hide();
    this.myStudentStudyingList.show();

    this.dataStudyingById = dataItem;
  }
  closeStudyingList() {
    this.myStudentStudyingList.hide();
    this.myCountStudentStudying.show();
  }

  openViewGraduateTheCourse(dataItem: any) {
    this.myStudentGraduateTheCourse.hide();
    this.showGraduateTheCourse.show();
    this.dataGraduateTheCourseById = dataItem;

  }
  closeGraduateTheCourse() {
    this.showGraduateTheCourse.hide();
    this.myStudentGraduateTheCourse.show();
  }

  openFindReportCountStudentAndCertificate(data: any) {
    if (data != '') {
      this.myCountStudentAndCertificate.show();
      this.dataStudentAndCertificate = data;
      this.loadcertificate();
      // console.log("--->",this.dataStudentAndCertificate);
    }
  }

  openDataStudyingList(data: any) {
    if (data != '') {
      this.myCountStudentStudying.show();
      this.studyingList = data;
    }
  }
  openViewCertificate(dataItem: any) {
    this.myCountStudentAndCertificate.hide();
    this.printCertificate.show();
    this.dataItemByTd = dataItem;
    this.findAllStudentCertificate(dataItem.dataStudent.stdntId);
  }

  findAllStudentCertificate(studentId: any) {
    this.reportAllService.findAllStudentCertificate(studentId).then((res) => {
      if (res.status) {
        this.userCertificateData = res.dataCertificate;
        var lengths = res.dataCertificate.length;
        for (let index = 0; index < lengths; index++) {
          this.showCertificate = true;
          this.certificateId = this.userCertificateData[index].csId;
          this.btnDownload = true;
          const date = new Date(res.dataCertificate[index].dateCreateCer);
          const result = date.toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          this.dateCreate = result;
          break;
        }
      }
    });
  }

  FindReportStudentInformationStudying(subjectId: string) {
    var data = [];
    this.reportAllService.FindReportStudentInformationStudying(subjectId).then(async (res) => {
      // console.log("..>", res);

      if (res.status) {
        var dataList = res.data;
        if (dataList.dataStudying != null) {
          for (let i = 0; i < dataList.dataStudying.length; i++) {
            var id = dataList.dataStudying[i].dataExamfinal.dataSubject.subjectId;
            var idStd = dataList.dataStudying[i].dataStudent.stdntId;
            await this.loadLearnedData(id, idStd);
            if (dataList.dataStudying[i].dataBuyCourse.statusPay == "successful") {
              data.push({
                "progress": this.progress,
                "dataBuyCourse": dataList.dataStudying[i].dataBuyCourse,
                "dataExamfinal": dataList.dataStudying[i].dataExamfinal,
                "dataStudent": dataList.dataStudying[i].dataStudent,
              });
            }
          }
        }
      }
      var dataFilter = data.filter(f => f.progress != '100');
      this.studyingCount = dataFilter;

      // this.chartReportStudying = {
      //   series: [
      //     {
      //       name: "Studying",
      //       data: [this.studyingCount.length, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      //       color: "#2B908F",
      //     }
      //   ],

      //   chart: {
      //     height: 350,
      //     type: "line",
      //     zoom: {
      //       enabled: false
      //     }
      //   },
      //   dataLabels: {
      //     enabled: false
      //   },
      //   stroke: {
      //     curve: "straight"
      //   },
      //   // title: {
      //   //   text: "Product Trends by Month",
      //   //   align: "left"
      //   // },
      //   grid: {
      //     row: {
      //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5
      //     }
      //   },
      //   xaxis: {
      //     categories: [
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Dec"
      //     ]
      //   }
      // };

      this.onclickChartStudying()
    })

  }
  async loadLearnedData(subjectId: string, idStd: string) {
    var array = [];
    var countExam: number = 0;
    var numbers: any;

    await this.reportAllService.FindPostTestBySubject(subjectId, idStd).then(res => {
      if (res.status == true) {
        if (res.data != undefined) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].statusResult == "successful") {
              this.exam = res.data[i].statusResult;
              // res.data.length = 0;
            } else if (res.data[i].statusResult == "waitAnswer") {
              this.exam = res.data[i].statusResult;
            } else {
              this.exam = res.data[i].statusResult;
            }
          }
        }
      } else {
        this.exam = "no exam";
      }
    });

    await this.reportAllService.FindLearnStudentBySubject(subjectId, idStd).then(response => {
      if (response.status) {
        this.dataLearnStudent = response;
        var lengths = this.dataLearnStudent.data.length;
        for (let index = 0; index < lengths; index++) {
          if (this.dataLearnStudent.data[index].dataStudentLearn != null) {
            if (this.dataLearnStudent.data[index].dataStudentLearn.learnStatus == true) {
              array.push(this.dataLearnStudent.data[index]);
            }
          }
        }
        if (this.exam == "failed") {
          countExam = 1;
        }
        // if (this.exam == "no") {
        //   countExam = 0;
        // }
        if (this.exam == "no exam") {
          countExam = 1;
        }

        if (countExam >= 1) {
          numbers = (array.length * 100 / lengths) - countExam;
        } else {
          numbers = (array.length * 100 / lengths);
        }
        if (array.length == 0 && lengths == 0) {
          numbers = 0;
        }
        if (numbers == -1) {
          numbers = 0;
        }

        var formattedNumber = formatNumber(numbers, this.locale, '1.0-0');
        this.progress = formattedNumber.toString();
      }
    }, error => {
      console.log("error-FindLearn : ", error);
    });
  }

  closeCer() {
    this.printCertificate.hide();
    this.myCountStudentAndCertificate.show();
  }
  async generateImage() {
    this.dataLoadingClickCer = true;
    var ff: string;
    await htmlToImage
      .toJpeg(document.getElementById('image-section'), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'Certificate.jpeg';
        link.href = dataUrl;
        link.click();
        ff = "success";
      });
    if (ff == "success") {
      this.dataLoadingClickCer = false;
    }

    // var url = "http://localhost:5000/api/Certificate/ViewCertificate?certificateId="+certificateId;
    // window.open(url, "_blank");
  }

  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }


  clearDateAll() {
    this.startDate = document.getElementById('start');
    this.endDate = document.getElementById('end');
    this.getDataSubjectTeacher(this.selectedTeacher);
  }

  clearDateEnd() {
    this.endDate = "YYYY-MM-DD";
  }
  searchDate(dateEnd: any) {
    const datalistExamSubject = this.dataExamSubject;
    var filterDataDatePretest = datalistExamSubject.pretest.filter(f => moment(f.dataPreTest.date).format("YYYY-MM-DD") >= this.startDate && moment(f.dataPreTest.date).format("YYYY-MM-DD") <= dateEnd.target.value);
    this.pretestList = {
      pretest: filterDataDatePretest
    };

    for (let i = 0; i < datalistExamSubject.posttest.length; i++) {
      var dataPostTest = datalistExamSubject.posttest[i].dataPostTest;
      var filterDataDatePosttest = datalistExamSubject.posttest[i].dataPostTest.filter(f => moment(f.date).format("YYYY-MM-DD") >= this.startDate && moment(f.date).format("YYYY-MM-DD") <= dateEnd.target.value);
      this.posttestList = {
        posttest: filterDataDatePretest
      };
    }


  }
  filterPretest() {
    if (this.searchTermPretest) {
      this.filteredPretest = this.pretestList.pretest.filter(item =>
        item.dataStudent.fullNameEn.toLowerCase().includes(this.searchTermPretest.toLowerCase()) ||
        item.dataStudent.fullNameTh.toLowerCase().includes(this.searchTermPretest.toLowerCase()) ||
        item.dataStudent.email.toLowerCase().includes(this.searchTermPretest.toLowerCase()) ||
        item.dataStudent.phoneNumber.includes(this.searchTermPretest)
      )
    } else {
      this.filteredPretest = this.pretestList.pretest;
    }
  }
  get paginatedPretest() {
    const startIndex = (this.currentPagePretest - 1) * this.pageLengthPretest;
    const endIndex = this.currentPagePretest * this.pageLengthPretest;
    return this.filteredPretest.slice(startIndex, endIndex);

  }
  updatePaginationPretest() {
    return Math.ceil(this.filteredPretest.length / this.pageLengthPretest);
  }
  setPagePretest(page: number) {
    if (page >= 1 && page <= this.updatePaginationPretest()) {
      this.currentPagePretest = page;
    }
  }
  filterPosttest() {
    if (this.searchTermPosttest) {
      this.filteredPosttest = this.posttestList.posttest.filter(item =>
        item.dataStudent.fullNameEn.toLowerCase().includes(this.searchTermPosttest.toLowerCase()) ||
        item.dataStudent.fullNameTh.toLowerCase().includes(this.searchTermPosttest.toLowerCase()) ||
        item.dataStudent.email.toLowerCase().includes(this.searchTermPosttest.toLowerCase()) ||
        item.dataStudent.phoneNumber.includes(this.searchTermPosttest)
      )
    } else {
      this.filteredPosttest = this.posttestList.posttest;
    }
  }
  get paginatedPosttest() {
    const startIndex = (this.currentPagePosttest - 1) * this.pageLengthPosttest;
    const endIndex = this.currentPagePosttest * this.pageLengthPosttest;
    return this.filteredPosttest.slice(startIndex, endIndex);

  }
  updatePaginationPosttest() {
    return Math.ceil(this.filteredPosttest.length / this.pageLengthPosttest);
  }
  setPagePosttest(page: number) {
    if (page >= 1 && page <= this.updatePaginationPosttest()) {
      this.currentPagePosttest = page;
    }
  }
  loadcertificate() {
    this.reportAllService.findCertificate().then(
      (response) => {
        if (response.status) {
          this.dataCertificate = response.dataCertificate;
          this.imgLogo = this.baseURL + this.dataCertificate.logoBg;
          this.imgsignature = this.baseURL + this.dataCertificate.signature;
          setInterval(() => {
            this.dataLoadingCer = false;
          }, 1000);
        }
      },
      (error) => {
        console.log('error : ', error);
      }
    );
  }

  public openPDF(background: any): void {
    var backgroundFull;
    if (background == 1) {
      backgroundFull = '../../../../assets/images/certificate_bg/Certificate_1.jpg';
    } else if (background == 2) {
      backgroundFull = '../../../../assets/images/certificate_bg/Certificate_2.jpg';
    } else {
      backgroundFull = '../../../../assets/images/certificate_bg/Certificate_3.jpg';
    }
    let DATA: any = document.getElementById('image-section');
    html2canvas(DATA, { scale: 5 }).then((canvas) => {
      let PDF = new jsPDF({ unit: 'mm', format: 'a4', orientation: 'l' });
      let fileWidth = 297;
      let fileHeight = 210;
      // let fileHeight = (canvas.height * fileWidth) / canvas.width;
      let position = 0;
      const FILEURI = canvas.toDataURL("image/jpeg", 1.0);
      let imaback = new Image();
      imaback.src = backgroundFull;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      let img = new Image();
      img.src = this.imgLogo;
      PDF.addImage(img, 'PNG', (fileWidth - 38) * 0.5, 5, 38, 38);
      // PDF.addImage(img, 'PNG', 128, 5, 38, 38);
      let imgSignature = new Image();
      imgSignature.src = this.imgsignature;
      // PDF.addImage(imgSignature, 'PNG', 135, 158, 60, 20);
      PDF.addImage(imgSignature, 'PNG', (fileWidth - 60) * 0.5, 158, 60, 20);
      PDF.save('Certificate.pdf');
    });
  }


}
